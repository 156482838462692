// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name=' + this.hash.slice(1) +']');
      if ($target.length) {
        var targetOffset = $target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'quart');
        return false;
      }
    }
  });

});

// slide btn

$(function(){
  $(".js-slideBtn").each(function(index,element){
      $(element).click(function(){
        $(this).toggleClass('is-active');
        $(element).next(".js-slidePanel").slideToggle(300);
        });
      });

  // ハッシュがあるときはオープン
  if(window.location.hash.length>0){
    $(window.location.hash).toggleClass('on').next(".js-slidePanel").toggle();
  }
});


// current

$(function(){
  var gNavItem = $("#gnav .js-gnavItem");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });

});

// header logo transform

$(window).on('load scroll', function(){

  var mainLogo = $('#js-logo');
  var scroll = $(window).scrollTop();

  if(scroll > 5) {
    mainLogo.addClass('is-small');
  } else {
    mainLogo.removeClass('is-small');
  }
});


// header fadeIn/Out

/*
$(window).scroll(function(){
  var header = $('header');
  var scroll = $(window).scrollTop();

  if(scroll > 900) {
    header.fadeIn(200);
  } else {
    header.fadeOut(200);
  }
});
*/


//fadein

$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});